//企业管理,项目管理,人员管理
import { request } from "./request"

// 添加公司
export function addFirm(params) {
    return request({
        url: "api/firm/crt/",
        method: "post",
        data: params
    })

}

// 获取所有公司
export function getFirm(params) {
    return request({
        url: "api/firm/get/",
        method: "post",
        data: params

    })

}

// 删除公司
export function deleteFirm(msg) {
    return request({
        url: "api/firm/del/",
        method: "post",
        data: msg
    })

}

// 修改公司
export function editFirm(msg) {
    return request({
        url: "api/firm/upd/",
        method: "post",
        data: msg
    })

}

// 添加项目
export function addProjectInfo(msg) {
    return request({
        url: "v3/002/",
        method: "post",
        data: msg
    })

}
// 添加项目
export function addProjectInfo1(msg) {
    return request({
        url: "v3/002/",
        method: "get",
        params: msg
    })

}

// 获取项目
export function getProjectInfo(msg) {
    return request({
        url: "v3/017/",
        method: "post",
        data: msg
    })

}

// 修改项目
export function editProjectInfo(msg) {
    return request({
        url: "api/Project/upd/",
        method: "post",
        data: msg
    })

}

// 删除项目
export function deleteProjectInfo(msg) {
    return request({
        url: "api/Project/del/",
        method: "post",
        data: msg
    })

}

// 添加用户
export function addUserInfo(params) {
    return request({
        url: 'api/user/crt/',
        method: 'post',
        data: params
    })
}

// 获取用户
export function getUserInfo(msg) {
    return request({
        url: 'v3/013/',
        method: 'get',
        params: msg
    })
}

// 修改用户
export function editUserInfo(msg) {
    return request({
        url: 'api/user/upd/',
        method: 'post',
        data: msg
    })
}

// 删除用户
export function deleteUserInfo(msg) {
    return request({
        url: 'api/user/del/',
        method: 'post',
        data: msg
    })
}

// 项目添加施工要求
export function addConstruction(msg) {
    return request({
        url: 'api/pro_i/',
        method: 'post',
        data: msg
    })
}
//////////////////////////////////////
//添加
export function addprojectionfo(msg) {
    return request({
        url: 'api/hidd/xz/',
        method: 'get',
        params: msg
    })
}
export function addprojectionfo1(msg) {
    return request({
        url: 'api/hidd/crt/',
        method: 'post',
        data: msg
    })
}
export function show_Department(msg) {
    return request({
        url: 'api/show_Department/',
        method: 'post',
        data: msg
    })
}
export function add_Department(msg) {
    return request({
        url: 'api/add_Department/',
        method: 'post',
        data: msg
    })
}
export function get_show_Department(msg) {
    return request({
        url: 'api/show_Department/',
        method: 'get',
        params: msg
    })
}

// 修改操作
export function Department_up(msg) {
    return request({
        url: 'api/Department_del/',
        method: 'post',
        data: msg
    })
}
//删除操作
export function Department_del(msg) {
    return request({
        url: 'api/Department_del/',
        method: 'get',
        params: msg
    })
}



// 获取选中的风险详细信息
export function getThreeRiskInfo(msg) {
    return request({
        url: "api/ris_i/",
        method: "get",
        params: msg
    })
}


// 修改i选中的风险详细信息
export function update_Risk_data(msg) {
    return request({
        url: "api/update_Risk_data/",
        method: "post",
        data: msg
    })
}



// 绑定微信号
export function weixin_opid(msg) {
    return request({
        url: "api/weixin_opid/",
        method: "post",
        data: msg
    })
}


export function Personnel_post(msg) {
    return request({
        url: "v3/021/",
        method: "post",
        data: msg
    })
}

// 获取选中的风险详细信息
export function Personnel_get(msg) {
    return request({
        url: "v3/021/",
        method: "get",
        params: msg
    })
}


export function Personnel_post1(msg) {
    return request({
        url: "v3/027/",
        method: "post",
        data: msg
    })
}

//公司列表
export function listsOfCompanies(msg) {
    return request({
        url: "v3/028/",
        method: "post",
        data: msg
    })
}

//通过审核
export function approved(msg) {
    return request({
        url: "v3/029/",
        method: "post",
        data: msg
    })
}
//通过审核
export function approveds(msg) {
    return request({
        url: "v3/003/",
        method: "post",
        data: msg
    })
}


export function obtainDealers(msg) {
    return request({
        url: "v3/030/",
        method: "post",
        data: msg
    })
}

export function zhifu_weixin(msg) {
    return request({
        url: "v3/006/",
        method: "post",
        data: msg
    })
}
export function zhifu_zhifubao(msg) {
    return request({
        url: "v3/031/",
        method: "post",
        data: msg
    })
}


export function rechargeOrNot(msg) {
    return request({
        url: "v3/006/",
        method: "get",
        params: msg
    })
}


export function rechargeRecords(msg) {
    return request({
        url: "v3/007/",
        method: "get",
        params: msg
    })
}

export function xiguainianfei(msg) {
    return request({
        url: "v3/033/",
        method: "post",
        data: msg
    })
}

export function expirationTime(msg) {
    return request({
        url: "v3/034/",
        method: "post",
        data: msg
    })
}