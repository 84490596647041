<template>
    <div>
        <el-card>
            <!-- <el-form :inline="true" class="demo-form-inline">

                <el-form-item label="项目名称:">
                    <el-input placeholder="请输入项目名称" v-model="pro_name"></el-input>
                </el-form-item>

                <el-form-item label="公司名称:">

                    <el-input placeholder="请输入公司名称" v-model="num"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit">查询</el-button>
                    <el-button type="warning" @click="reset">重置</el-button>
                </el-form-item>
            </el-form> -->
            <!-- //表格 -->
            <el-table :data="tableData" stripe style="width: 100%" :header-cell-style="{
                background: '#4d7cfe',
                color: '#ffffff',
                textAlign: 'center',
            }">
                <el-table-column prop="Order_number" label="订单编号" align="center">
                </el-table-column>
                <el-table-column prop="Fir_name" label="所属公司" align="center">
                </el-table-column>
                <el-table-column prop="pro_name" label="项目名称" align="center">
                </el-table-column>
                <el-table-column prop="order_time" label="下单时间" align="center">
                </el-table-column>
                <el-table-column prop="time_ofpayment" label="支付时间" align="center">
                </el-table-column>
                <el-table-column prop="amount_paid" label="支付金额" align="center">
                </el-table-column>
                <el-table-column prop="order_status" label="支付状态" align="center">
                </el-table-column>
                <el-table-column prop="username" label="支付人员" align="center">
                </el-table-column>
            </el-table>
            <!-- //分页 -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page"
                :page-sizes="[2, 5, 10, 20]" :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper"
                :total="total">
            </el-pagination>
        </el-card>
    </div>
</template>

<script>
import {
    rechargeRecords
} from "../../../request/project";
export default {
    data() {
        return {
            tableData: [],
            page: 1,
            pagesize: 10,
            total: 0,
        }
    },

    components: {},

    computed: {},

    mounted() {
        this.rechargeRecords()
    },

    methods: {
        async rechargeRecords() {
            let msg = {
                unique_identification: localStorage.getItem('uid'),
                page: this.page,
                size: this.pagesize
            }
            const res = await rechargeRecords(msg);
            this.tableData = res.data.data
            this.total = res.data.count
        },
        //分页
        handleSizeChange(pagesize) {
            // console.log(`每页 ${pagesize} 条`);
            this.pagesize = pagesize;
            this.rechargeRecords();
        },
        handleCurrentChange(page) {
            // console.log(`当前页: ${page}`);
            this.page = page;
            this.rechargeRecords();
        },
    }
}

</script>
<style  scoped></style>